import React from 'react'
import style from './banneradd.module.css'
function BannerAdd2() {
  return (
    <div className={style.bannerAdd2} id='BannerADD2__ShopHome'>
        <img src='https://salt.tikicdn.com/cache/w400/ts/banner/83/25/b8/d575a05a850fc7e88637b43427d562ae.png.webp' />
        <img src='https://salt.tikicdn.com/cache/w400/ts/banner/83/25/b8/d575a05a850fc7e88637b43427d562ae.png.webp' />
        <img src='https://salt.tikicdn.com/cache/w400/ts/banner/83/25/b8/d575a05a850fc7e88637b43427d562ae.png.webp' />
    </div>
  )
}

export default BannerAdd2